<template>
  <div class="footer">
    <div class="footer__items">
      <div class="footer__item">
        <div
          class="text"
          v-html="$sanitize(footer?.content.text)"
        >
        </div>
      </div>
      <div
        v-if="footer?.content.showSitemap"
        class="footer__item links"
      >
        <router-link
          v-for="(link, i) in footer?.content.sitemapLinks"
          :key="i"
          tag="a"
          :to="`/${link}`"
        >
          {{ link ? $dictionary[link] : $dictionary.exchange }}
        </router-link>
      </div>
      <div
        v-if="footer?.content.showPartners"
        class="footer__item partners"
      >
        <div
          v-for="partner in partners"
          :key="partner.link"
          class="container"
        >
          <a :href="partner.link" target="_blank" rel="noopener noreferrer">
            <img
              :src="partner.logo"
              class="footer__img"
            >
          </a>
        </div>
      </div>
      <div class="footer__item">
        <div class="container">
          <a :href="footer?.content.telegramLink" target="_blank">
            <img
              src="@/assets/img/tg.png"
              alt="telegram_logo"
              class="footer__img"
            >
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer',
  data () {
    return {
    }
  },
  computed: {
    footer () {
      return this.$store.state.global.components.footer
    },
    partners () {
      return this.footer?.content.showPartners ? this.$store.state.global.components.partners.content.partners : []
    }
  },
}
</script>
