import axios from 'axios'
import { Message } from 'element-ui';
import dictionary from '../../global/dictionary';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const client = axios.create({
  baseURL: process.env.VUE_APP_REST_API_BASE
})

async function getFingerprint() {
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  return result.visitorId; // Уникальный fingerprint
}

client.interceptors.request.use(async config => {
  const token = localStorage.getItem('accessToken'); // Получение токена из localStorage (или откуда у вас хранится)
  if (token) {
    config.headers.Authorization = `Bearer ${token}`; // Добавление заголовка Authorization
  }
  const fingerprint = await getFingerprint();
  config.headers['Fingerprint'] = fingerprint;

  return config;
})

client.interceptors.response.use(
  response => response, // Возвращаем ответ, если всё ок
  error => {
    if (error.response?.status === 429) {
      Message.error(dictionary.rateLimit);
    }
    if (error.response?.status === 403) {
      Message.error(dictionary.accessError);
    }
    return Promise.reject(error);
  }
);

export default client
