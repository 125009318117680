import Vue from 'vue'
import DOMPurify from 'dompurify'

Vue.prototype.$sanitize = (html) => {
  return DOMPurify.sanitize(html, {
    // ALLOWED_TAGS: ['a', 'br'], // Разрешенные теги
    // ALLOWED_ATTR: ['href', 'class', 'target', 'rel'], // Разрешенные атрибуты
    ALLOWED_URI_REGEXP: /^(https?|tg):/i // Разрешаем ссылки `http`, `https` и `tg`
  });
};
